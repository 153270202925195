import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    addButton: {
        'text-transform': 'none',
        textAlign: 'center',
    },
}));

export function CustomToolbar(props) {
    const classes = useStyles();

    const { addedTrigger } = props

    return (

        <Button
            variant="contained"
            color="secondary"
            className={classes.addButton}
            endIcon={<AddIcon />}
            onClick={(e) => addedTrigger(e)}
            aria-label="add to COA"
        >
            Add to COA
        </Button>


    );
}
