import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { CustomTableToolbar } from './CustomTableToolbar'

/**
 * 
 *  This is the datatable on the left. From here, you can add samples to the right table <AddedDataTable /> 
 *  
 */

export function DataTable(props) {

    const {
        rowsData,
        title,
        handleOpenTools,
        handleRowsExpanded,
        getBatchColumns,
        handleBatchTableChange,
        getRowsExpanded,
        handleExpandChange,
        getExpandColumns,
        getExpandRowsSelected,
        getExpandSearchText,
        getBatchSearchText,
        handleSearchBatchID,
        setBatchIDSearch,
        batchIDSearch,
        handleDateChange,
        selectedDate,
        handleFirstTen,
        dateSearch,
        setDateSearch,
        searchLoading
    } = props;

    function onEnter(e) {
        if (e.keyCode === 13) {
            handleSearchBatchID(e.target.value)
        }
    }

    const [openDatePicker, setOpenDatePicker] = useState(false);

    return (
        rowsData &&

        <MUIDataTable
            title={title}
            data={rowsData}
            columns={getBatchColumns()}
            options={{

                //renders custom toolbar at top of table before sample data
                customToolbar: () => {
                    return (
                        <CustomTableToolbar
                            rowsData={rowsData}
                            handleFirstTen={handleFirstTen}
                            setBatchIDSearch={setBatchIDSearch}
                            onEnter={onEnter}
                            batchIDSearch={batchIDSearch}
                            handleSearchBatchID={handleSearchBatchID}
                            handleDateChange={handleDateChange}
                            selectedDate={selectedDate}
                            setOpenDatePicker={setOpenDatePicker}
                            openDatePicker={openDatePicker}
                            dateSearch={dateSearch}
                            setDateSearch={setDateSearch}
                            searchLoading={searchLoading}
                        />
                    )
                },
                pagination: false,
                rowsPerPageOptions: [],
                filter: false,
                rowsExpanded: getRowsExpanded(),
                filterType: 'checkbox',
                selectableRows: 'none',
                expandableRows: true,
                print: false,
                download: false,
                // customSearch: (searchQuery) => handleSearch(searchQuery),
                search: false,
                onRowsExpand: (currentRowsExpanded, allRowsExpanded) => {
                    handleRowsExpanded([currentRowsExpanded[0].dataIndex])
                },

                renderExpandableRow: (rowData, rowMeta) => {
                    console.log('data &&&', rowsData && rowsData[rowMeta.dataIndex].samples)
                    return (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <MUIDataTable
                                    data={rowsData && rowsData[rowMeta.dataIndex].samples}
                                    columns={getExpandColumns()}
                                    options={{
                                        print: false,
                                        download: false,
                                        selectableRowsOnClick: true,
                                        elevation: 0,
                                        rowsSelected: getExpandRowsSelected(rowMeta),
                                        onRowsSelect: (currentRowsSelected, allRowsSelected) => {
                                            handleOpenTools(allRowsSelected)
                                        },
                                        disableToolbarSelect: true,
                                        onTableChange: (action, tableState) => handleExpandChange(action, tableState),
                                        searchText: getExpandSearchText(),
                                        filterType: 'multiselect',
                                        enableNestedDataAccess: "."
                                    }}

                                />
                            </TableCell>
                        </TableRow>
                    )
                },
                expandableRowsOnClick: true,

                searchText: getBatchSearchText(),

                onTableChange: (action, tableState) => handleBatchTableChange(action, tableState),

            }}

        />

    )
}
