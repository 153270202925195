import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';


export function CustomDatePicker(props) {

    const { disabled, label, handleDateChange, selectedDate } = props;

    function changeDate(value) {
        handleDateChange(value)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    disableFuture={true}
                    format="MM/dd/yyyy"
                    margin="dense"
                    disabled={disabled}
                    id="date-picker-inline"
                    label={label}
                    value={selectedDate}
                    onChange={changeDate}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}