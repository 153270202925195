import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({

    addButton: {
        'text-transform': 'none',
        marginRight: theme.spacing(1)
    },
    longField: {
        width: '300px'
    },
    description: {
        marginRight: theme.spacing(10)
    },
    saveButton: {
        'text-transform': 'none',
        marginRight: theme.spacing(10)

    },
    descriptionExists: {
        marginRight: theme.spacing(2)
    }
}));

export function AddedDataTable(props) {
    const classes = useStyles();

    const {
        title,
        addedSamples,
        handleRemoveSamples,
        getRowsSelected,
        getColumns,
        handleAddedTableChange,
        saveDescription
    } = props;

    const [description, setDescription] = useState('')

    return (
        <MUIDataTable
            title={title}
            data={addedSamples}
            columns={getColumns()}
            options={{
                selectableRowsOnClick: true,
                filterType: 'checkbox',
                textLabels: {
                    body: {
                        noMatch: "Add samples from the select samples table.",
                    },
                },
                print: false,
                download: false,
                search: false,
                filter: false,
                viewColumns: false,
                enableNestedDataAccess: ".",

                rowsSelected: getRowsSelected(),

                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div>
                            <TextField
                                className={description ? classes.descriptionExists : classes.description}
                                label="Edit Description"
                                color="primary"
                                variant="outlined"
                                margin="dense"
                                onChange={e => setDescription(e.target.value)}
                            />

                            {description &&
                                <Button
                                    variant={"contained"}
                                    color="primary"
                                    className={classes.saveButton}
                                    endIcon={<SaveIcon />}
                                    onClick={() => saveDescription(description)}
                                    aria-label="save"
                                >
                                    Save
                            </Button>
                            }

                            <IconButton
                                variant="contained"
                                color="primary"
                                className={classes.addButton}
                                onClick={() => handleRemoveSamples(getRowsSelected())}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )
                },
                onTableChange: (action, tableState) => handleAddedTableChange(action, tableState),
            }}
        />
    )
}
