import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuth0 } from "./auth0";

import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from '@material-ui/core/LinearProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { NavBar } from "./shared/components/NavBar";
import { formatToDate } from './shared/helper-functions/dates'
import { Dashboard } from './pages/Dashboard'
import { apiRequest } from './shared/helper-functions/apiRequest';



export default function App() {

	const [firstTenBatches, setFirstTenBatches] = useState([])
	const { isAuthenticated, loginWithRedirect } = useAuth0();
	const [batches, setBatches] = useState([])
	const [added, setAdded] = useState(0)
	const [selectedRows, setSelectedRows] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [rowsData, setRowsData] = useState([])
	const [displayError, setDisplayError] = useState(false)
	const [searchLoading, setSearchLoading] = useState(false)
	const [openTools, setOpenTools] = useState(false)


	const classes = useStyles();

	const { loading } = useAuth0();

	function handleFirstTen() {
		setDisplayError(false);
		createData(firstTenBatches)
	}

	function createData(receivedData) {

		receivedData && receivedData.map((batch, index) => {
			const sampleBatch = batch.Samples.Sample[0].Batch[0]
			const sampleCollectedDate = batch.Samples.Sample[0].CollectedDate[0]
			const samples = batch.Samples.Sample
			const dbDate = batch.dbDate
			const data = { sampleBatch, sampleCollectedDate, samples, dbDate }

			setRowsData(rowsData => rowsData.concat(data))

			return null;
		})
	}

	function createCustomData(receivedData) {

		setRowsData([]);
		receivedData && receivedData.map((batch, index) => {

			const sampleBatch = batch.Samples.Sample[0].Batch[0]
			const sampleCollectedDate = batch.Samples.Sample[0].CollectedDate[0]
			const samples = batch.Samples.Sample
			const dbDate = batch.dbDate

			const data = { sampleBatch, sampleCollectedDate, samples, dbDate }

			setRowsData(rowsData => rowsData.concat(data))

			return null;
		})
	}

	function handleOpenTools(allRowsSelected) {
		if (allRowsSelected.length > 0) {
			setOpenTools(true)
			setSelectedRows(allRowsSelected)
		}
		else
			setOpenTools(false)
	}


	function handleSearchBatchID(searchQuery) {
		setSearchLoading(true)
		setDisplayError(false);
		let searchTerm = searchQuery.toString()
		let arr = [];
		apiRequest('post', 'batches/searchBatch', function callback(data) {
			if (data === 'error')
				setDisplayError(true);
			else {
				setSearchLoading(false)
				arr.push(data)
				createCustomData(arr)
			}
		}, { 'searchTerm': searchTerm })
	}

	function handleSearchDate(searchQuery) {
		console.log("searchQuery", searchQuery)
		setDisplayError(false);
		let searchTerm = formatToDate(searchQuery)

		apiRequest('post', 'batches/searchDate', function callback(data) {
			if (data === "none") {
				console.log("none")
				setDisplayError(true);
			}

			else {
				createCustomData(data)
			}
		}, { 'searchTerm': searchTerm })
	}

	useEffect(() => {
		if (batches.length > 0) {
			createData(batches);
		}
	}, [batches]);

	useEffect(() => {
		apiRequest('get', 'batches/firstTen', function callback(data) {
			if (data === "error") {
				return null
			}
			else {
				setFirstTenBatches(data)
				setBatches(data)
			}
		})
	}, []);

	function addedTrigger(e) {
		e.preventDefault();
		setAdded(added + 1)
	}

	if (loading && process.env.NODE_ENV === 'production') {
		return <MuiThemeProvider theme={theme}>
			<div>
				<LinearProgress color='primary' />
			</div>
		</MuiThemeProvider>;
	}

	return (
		!isAuthenticated && process.env.NODE_ENV === 'production' ? loginWithRedirect({}) :
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<div className={classes.container}>
					<NavBar
						addedTrigger={(e) => addedTrigger(e)}
						openTools={openTools}
					/>
					<Router>
						<Dashboard
							setCurrentPage={setCurrentPage}
							setSelectedRows={setSelectedRows}
							selectedRows={selectedRows}
							added={added}
							addedTrigger={addedTrigger}
							batches={batches}
							page={currentPage}
							handleSearchBatchID={handleSearchBatchID}
							createData={createData}
							rowsData={rowsData}
							handleSearchDate={handleSearchDate}
							displayError={displayError}
							handleFirstTen={handleFirstTen}
							searchLoading={searchLoading}
							openTools={openTools}
							handleOpenTools={handleOpenTools}
						/>
					</Router>
				</div>
			</MuiThemeProvider>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		'overflow-x': 'hidden',
		'overflow-y': 'hidden'
	},
}));

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#25256b'
		},
		secondary: {
			main: '#94d8ee'
		},
		background: {
			default: '#D3D3D3',
			paper: '#FFFFFF'
		},
	}
});
